"use client";

import { Fragment } from "react";
import { cn } from "@/lib/utils";
import { BinderImage } from "./binder-image";
import { BinderTag } from "./binder-tag";
import { BinderModal } from "./binder-modal";

export const Binder = ({ binder, layout = "grid", size = "lg" }) => {
  const {
    id,
    thumbnail_url,
    name,
    description,
    created_at,
    last_edited_on,
    curator,
    display_author,
    times_viewed,
    number_of_ratings,
  } = binder;

  const createdAt = new Date(created_at);
  const lastEdited = new Date(last_edited_on);
  const timeStamp = Math.round(new Date().getTime() / 1000);
  const timeStampYesterday = timeStamp - 24 * 3600;
  const isNew = createdAt >= new Date(timeStampYesterday * 1000).getTime();
  const isUpdated = lastEdited >= new Date(timeStampYesterday * 1000).getTime();
  const status = isNew ? "new" : isUpdated ? "updated" : null;

  return (
    <BinderModal
      binder={binder}
      trigger={({ setOpen }) => (
        <div
          className={cn(
            "flex justify-center w-full",
            layout === "row"
              ? ""
              : "transition ease-in-out duration-500 hover:scale-[1.015]"
          )}
          role="button"
          tabIndex={0}
          onClick={() => setOpen(true)}
        >
          <div
            className={cn(
              "w-full",
              layout === "row" ? "flex" : "text-center block max-w-[200px]"
            )}
          >
            <div
              className={cn(
                "grow-0 shrink-0 basis-[200px]",
                layout === "row" ? "mr-14" : null
              )}
            >
              <BinderImage src={thumbnail_url} alt={name}>
                <BinderTag status={status} />
              </BinderImage>
            </div>
            <div>
              <h3 className={cn(size === "sm" ? "!text-xs" : "!text-lg")}>
                {name}
              </h3>
              {layout === "row" && (
                <Fragment>
                  <div className="prose my-5">{description}</div>
                  <ul className="">
                    <li>
                      Author:{" "}
                      <span className="font-semibold">
                        {display_author ? display_author : curator}
                      </span>
                    </li>
                    <li>
                      Views:{" "}
                      <span className="font-semibold">{times_viewed}</span>
                    </li>
                  </ul>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      )}
    />
  );
};
