"use client";

import Link from "next/link";
import { Modal } from "@/components/modal";
import { Hero } from "@/components/hero";
import { Button } from "@/components/button";
import { BinderImage } from "./binder-image";
import { BinderTag } from "./binder-tag";

export const BinderModal = ({ binder, trigger }) => {
  const {
    id,
    thumbnail_url,
    name,
    description,
    created_at,
    last_edited_on,
    curator,
    display_author,
    times_viewed,
    number_of_ratings,
  } = binder;

  const createdAt = new Date(created_at);
  const lastEdited = new Date(last_edited_on);
  const timeStamp = Math.round(new Date().getTime() / 1000);
  const timeStampYesterday = timeStamp - 24 * 3600;
  const isNew = createdAt >= new Date(timeStampYesterday * 1000).getTime();
  const isUpdated = lastEdited >= new Date(timeStampYesterday * 1000).getTime();
  const status = isNew ? "new" : isUpdated ? "updated" : null;
  const url = `${process.env.NEXT_PUBLIC_RAILS_PROTOCOL}://${process.env.NEXT_PUBLIC_RAILS_DOMAIN}/play/play?id=${id}`;

  return (
    <Modal trigger={trigger}>
      <div>
        <Hero title={name} size="sm" />
        <div className="p-5">
          <BinderImage
            className="max-w-[110px] mx-auto -mt-16"
            src={thumbnail_url}
            alt={name}
          >
            <BinderTag status={status} />
          </BinderImage>
          <div className="text-left mt-8">
            <div className="prose my-5">{description}</div>
            <ul className="">
              <li>
                Author:{" "}
                <span className="font-semibold">
                  {display_author ? display_author : curator}
                </span>
              </li>
              <li>
                Views: <span className="font-semibold">{times_viewed}</span>
              </li>
            </ul>
            <Button className="mt-10" asChild>
              <Link href={url}>View Binder</Link>
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
