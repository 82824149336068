import { useState, useEffect } from "react";

export const useLoggedIn = () => {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    // Skip check if running on localhost
    if (
      typeof window !== "undefined" &&
      window.location.hostname === "localhost"
    ) {
      return;
    }

    const checkLoggedIn = async () =>
      fetch(
        `${process.env.NEXT_PUBLIC_RAILS_PROTOCOL}://${process.env.NEXT_PUBLIC_RAILS_DOMAIN}/login/logged_in?ws=json`,
        {
          cache: "no-store",
        }
      ).then((response) => response.json());

    checkLoggedIn().then((data) =>
      setLoggedIn(data.logged_in === "true" || false)
    );
  }, []);

  return loggedIn;
};
